<script setup lang="ts">
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseHeader from '/~/components/base/header/base-header.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

const emit = defineEmits(['close', 'cancel'])

function onClose() {
  emit('close')
}

function onCancel() {
  emit('cancel')
}
</script>

<template>
  <div>
    <base-header
      type="closable"
      class="flex items-center justify-center p-7 pb-5 sm:pb-3"
      @close="onClose"
    >
      <div class="">
        <base-icon
          :size="64"
          svg="heroicons/solid/exclamation-circle"
          class="text-fg-error"
        />
      </div>
    </base-header>
    <div class="space-y-5 p-6 pt-0 sm:p-8 sm:pb-6 sm:pr-9 sm:pt-0">
      <div class="text-center text-2xl font-bold tracking-tight">
        Account Pending Review
      </div>

      <div class="pt-0 text-base sm:pt-6">
        Your account is undergoing a standard additional review. We’ll notify
        you once it’s ready to proceed.
      </div>

      <div class="flex justify-center pt-2 sm:pt-7">
        <base-button class="basis-1/2" look="outlined-color" @click="onCancel">
          Close
        </base-button>
      </div>
    </div>
  </div>
</template>
